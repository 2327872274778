/** Cosmos 1: Satellite **/

import React from "react";
import { preloadImages, scaleFullscreen, showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { gsap, Linear } from "gsap";
import Sound from 'react-sound';

let globalState, data, preloadData, dustTL1, dustTL2;

class CosmosPage1 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[1];
        preloadData = globalState.assets[2];

        //refs
        this.fullscreenBg = null;
    }

    state = {
        textVisible: false,
        explosionSound: false
    }




    componentDidMount = () => {


        //position text
        positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);
        positionText(this.refs.text2Ref, "right", "top", globalState.textPosition.rightTop);


        //show text
        gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });
        gsap.to(this.refs.text2Ref, 0, { autoAlpha: 1.0 });

        //update base track volume
        globalState.baseAudioRef.updateVolume(2, 85);
        
        //add base track
        globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1); //satellite

        //remove any existing vo
        globalState.baseAudioRef.updateTrack(3, false);


        //Update dimensions
        this.updateDimensions();

        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);

        //Satellite
        gsap.set(this.refs.satelliteRef, { x: "-48vw", y: "-40vh", z: 20000, scaleX: 0, scaleY: 0 });
        gsap.to(this.refs.satelliteRef, 12, { x: 0, y: 0, z: -500, rotation: -310, ease: "power4.in", onComplete: this.showSatelliteExplosion, delay: 9, scaleX: 2, scaleY: 2 });


        //Flash version
        //time: 17
        //delay: 4

        //Position explosion and dust
        gsap.set(this.refs.explosionRef, { rotation: 45, scaleX: 2, scaleY: 2 });

        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.bgVideoPoster,
            globalState.cdnUrl + preloadData.caption],
            this.preloadCallback);

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);
        gsap.killTweensOf(this.refs.satelliteRef);
        gsap.killTweensOf(this.refs.explosionRef);
        gsap.killTweensOf(this.refs.dustWrapperRef);
        gsap.killTweensOf(dustTL1);
        gsap.killTweensOf(dustTL2);

        window.removeEventListener("resize", this.updateDimensions);

        window.cancelAnimationFrame(this.reqAnimFrame);
    }

    showSatelliteExplosion = () => {
        //play sound
        this.setState({ explosionSound: true });

        gsap.to(this.refs.explosionRef, 0.1, { alpha: 1 });
        gsap.to(this.refs.explosionRef, 0.8, { rotation: 0, scaleX: 2.5, scaleY: 2.5 });
        gsap.to(this.refs.explosionRef, 0.2, { autoAlpha: 0, delay: 0.6 });
        gsap.set(this.refs.satelliteRef, { autoAlpha: 0 });


        //Animate dust
        gsap.to(this.refs.dustWrapperRef, 0.5, { alpha: 1, scaleX: 1.5, scaleY: 1.5 });
        dustTL1 = gsap.timeline({ repeat: -1 });
        dustTL2 = gsap.timeline({ repeat: -1, delay: 2 });

        dustTL1.to(this.refs.dust1Ref, 2.5, { alpha: 1, scaleX: 1, scaleY: 1, ease: Linear.easeNone });
        dustTL1.to(this.refs.dust1Ref, 2, { alpha: 0, scaleX: 1.5, scaleY: 1.5, ease: Linear.easeNone });

        dustTL2.to(this.refs.dust2Ref, 2.5, { alpha: 1, scaleX: 1, scaleY: 1, ease: Linear.easeNone });
        dustTL2.to(this.refs.dust2Ref, 2, { alpha: 0, scaleX: 1.5, scaleY: 1.5, ease: Linear.easeNone });

        this.reqAnimFrame = window.requestAnimationFrame(this.spinDust);

    }

    spinDust = (e) => {
        gsap.set(this.refs.dustWrapperRef, { rotation: "-=0.5" });
        this.reqAnimFrame = window.requestAnimationFrame(this.spinDust);
    }

    removeSatellite = () => {
        gsap.set(this.refs.satelliteRef, { autoAlpha: 0 });
    }

    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);
            showText(this.refs.text2Ref, "right", "top", globalState.textPosition.rightTop);


        } else {
            //hide text
            hideText(this.refs.text1Ref, "right");
            hideText(this.refs.text2Ref, "right");

        }
    }

    updateDimensions = () => {
        console.log('updateDimensions');
        //background native size is 1288 x 724
        scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1288, 779, false);
    }

    isTransitioning = () => {
        this.setState({ explosionSound: false });
        //pause animations
    }


    render() {

        return (

            <>
                <link rel="prefetch" href="/cosmos-954/2" />
                {preloadData && (
                <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo} />
                )}
                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/cosmos-954/2" prevLocation="/cosmos-954"
                    isTransitioning={this.isTransitioning} />

                {this.state.explosionSound ?
                    
                    <Sound
                        ref="explosionSoundRef"
                        url={globalState.cdnUrl + data.explosionSound}
                        playStatus={Sound.status.PLAYING}
                        position={0}
                        autoLoad={true}
                        loop={false}
                        onFinishedPlaying={() => {
                            this.setState({ explosionSound: false });
                        }}
                    /> : ""
                }
                {data && (
                <div className="fullpage-wrapper">
                    <SEO title="Pine Point - Cosmos 954" />

                    <div className="fullscreen-bg pinepointers-bg" ref="fullscreenBgRef">
                        <img src={globalState.cdnUrl + data.background} alt="background" className="background" />

                        <img src={globalState.cdnUrl + data.explosion} alt="Explosion" ref="explosionRef" className="explosion" />

                        <div ref="dustWrapperRef" className="dustWrapper">
                            <img src={globalState.cdnUrl + data.dust} alt="Dust" ref="dust1Ref" className="dust" />
                            <img src={globalState.cdnUrl + data.dust} alt="Dust" ref="dust2Ref" className="dust" />
                        </div>
                    </div>

                    <div className="satelliteWrapper">
                        <img src={globalState.cdnUrl + data.satellite} alt="Satellite" ref="satelliteRef" className="satellite" />
                    </div>

                    <div className="text-wrapper">
                        <div className="text text--right-top" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "100px", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="I REMEMBER JANUARY 24, 1978. That day a Russian satellite – Cosmo 954 – crashed near Yellowknife. After the fact, I convinced myself I’d seen little glinty pieces falling from the sky. It was a bit of vanity, really, like I had somehow orchestrated the whole thing. I suppose we all want to be involved. I’m pretty sure I remember a Japanese news crew coming to Yellowknife and feeling proud that they were there – that my little corner had the world’s attention (I was on the front lines of the Cold War now, a potential victim of a nuclear attack!). And I remember wondering – why hadn’t it been noticed before?" />
                        </div>
                        <div className="text text--right-top" ref="text2Ref">
                            <input type="image" src={globalState.cdnUrl + data.text2} style={{ marginRight: "50px", marginTop: "445px" }} onClick={this.toggleTextDisplay} alt="It seems Pine Pointers recollect their past more acutely than I do. They have more stories to tell, and those stories have more details. Like they know the whole guest list for every house party that happened 20 years ago. That’s how it seems, anyway." />
                        </div>
                    </div>

                </div>
                )}
            </>
        );
    }


};

export default CosmosPage1
